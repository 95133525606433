import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, from } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { BbTokenModel } from '../models/bb-token.model';
import { AuthModel } from '../models/auth.model';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { LoginService } from './login.service';
import { HttpCallService } from 'src/app/services/http-call.service';
import { GlobalConstants } from 'src/app/configurations/global-constants';

export type UserType = BbTokenModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<any>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: any) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private router: Router,
    private loginService: LoginService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private httpCallService: HttpCallService
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    // const subscr = this.getUserByToken().subscribe();
    // this.unsubscribe.push(subscr);
  }

  // public methods

  login(email: string, password: string): Observable<string> {
    this.isLoadingSubject.next(true);
    let loginPayload = {
      password: password,
      username: email,
    };
    return this.loginService.login(loginPayload).pipe(
      map((data: any) => {
        let user = {
          ...data['user'],
          fullname: data['user']['name'],
          role: data['user']['role'],
          token: data['token'],
          id: data['user']['id'],
          bid: data['user']['buyerId'],
          sid: data['user']['sellerId'],
          individual: data['user']['individual'],
          profilePic: data['user']['profilePic'],
        };
        this.userService.setUserDetails(user);
        this.currentUserSubject.next(user);
        return data['user']['role'];
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<string> {
    return this.loginService.forgotPassword(email).pipe(
      map((data: any) => {
        return data;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  loadUserToken(): Observable<UserType> {
    let userDetails = this.userService.getUserDetails() as UserType;
    if (userDetails) {
      this.currentUserSubject.next(userDetails);
      return of(userDetails);
    } else {
      // this.logout();
      return of(undefined);
    }
  }

  logout() {
    this.userService.removeUserDetails();
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  updateProfile(user: UserType) {
    this.currentUserSubject.next(user);
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    return of(undefined);
    // if (!auth || !auth.authToken) {
    //   return of(undefined);
    // }

    // this.isLoadingSubject.next(true);
    // return this.authHttpService.getUserByToken(auth.authToken).pipe(
    //   map((user: UserType) => {
    //     if (user) {
    //       this.currentUserSubject.next(user);
    //     } else {
    //       this.logout();
    //     }
    //     return user;
    //   }),
    //   finalize(() => this.isLoadingSubject.next(false))
    // );
  }

  // need create new user then login
  // registration(user: UserModel): Observable<any> {
  //   this.isLoadingSubject.next(true);
  //   return this.authHttpService.createUser(user).pipe(
  //     map(() => {
  //       this.isLoadingSubject.next(false);
  //     }),
  //     switchMap(() => this.login(user.email, user.password)),
  //     catchError((err) => {
  //       console.error('err', err);
  //       return of(undefined);
  //     }),
  //     finalize(() => this.isLoadingSubject.next(false))
  //   );
  // }

  // forgotPassword(email: string): Observable<boolean> {
  //   this.isLoadingSubject.next(true);
  //   return this.authHttpService
  //     .forgotPassword(email)
  //     .pipe(finalize(() => this.isLoadingSubject.next(false)));
  // }

  // // private methods
  // private setAuthFromLocalStorage(auth: AuthModel): boolean {
  //   // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
  //   if (auth && auth.authToken) {
  //     localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
  //     return true;
  //   }
  //   return false;
  // }

  private getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
