export class GlobalConstants {
  /**
   * Global modules
   */
  public static BB_SERVICE: string = 'bb-service';

  /**
   * bb-service endpoint methods
   */

  public static BB_AUTH_LOGIN: string = 'auth/signin';
  public static BB_AUTH_LOGOUT: string = 'auth/signout';

  public static BB_USER_UPDATE_PASSWORD = 'user/updatePassword';
  public static BB_USER_RESET_PASSWORD = 'user/resetPassward';
  public static BB_USER_FORGOT_PASSWORD = 'user/forgotPassward';
  public static BB_USER_CHECK_EXISTING = 'user/existing';

  public static BB_GET_SELLER_REQUESTS = 'seller/request';
  public static BB_GET_SELLER_WITH_ATTACHMENTS =
    'seller/getSellerDetailsWithAttachementsBySellerId';
  public static BB_SELLER_REQUEST_ACTION = 'seller/request/action';
  public static BB_GET_PROPERTIES = 'property';
  public static BB_CREATE_PROPERTIES = 'property';
  public static BB_PROPERTY = 'property';
  public static BB_PROPERTY_NEAR_BY_LOCATION = 'property/near/by/location';
  public static BB_BUYER_PARTICIPATE = 'property/par';
  public static BB_GET_PROPERTY_WITH_DETAILS = 'property/detail';
  public static BB_SELLER_PROPERTY_ACTION = 'property/par';
  public static BB_GET_ALL_PAR = 'property/par';
  public static BB_GET_ALL_PROPERTY_PAR_DETAILS = 'property/parDetails';
  public static BB_GET_ALL_PROPERTY_AUCTIONS = 'property/auctions';
  public static BB_PROPERTY_LEAD_BUYER = 'property/lead/buyer/';

  public static BB_GET_USERS = 'user';

  public static BB_BUYER_SIGNUP = 'buyer/signup';
  public static BB_SELLER_SIGNUP = 'seller/request/submit';
  public static BB_SELLER_CREATE = 'seller/create';
  public static BB_SELLER_UPDATE = 'seller/update';

  public static BB_GET_ALL_BUYERS = 'buyer/getAll';
  public static BB_GET_BUYER_DETAILS = 'buyer/get';
  public static BB_BUYER_UPDATE = 'buyer/update';
  public static BB_BUYER_DELETE = 'user';
  public static BB_GET_BUYER_PROFILE = 'buyer/profile';
  public static BB_GET_BROKER_DETAILS = 'buyer/broker';

  public static BB_GET_ALL_SELLERS = 'seller/get';
  public static BB_GET_SELLER_DETAILS = 'seller/get';

  public static BB_UPLOAD_FILE = 'attachment/upload';
  public static BB_DOWNLOAD_ATTACHMENT = 'attachment/download';
  public static BB_DOWNLOAD_FILE_BY_DOCID_NAME = 'attachment/download';

  public static BB_GET_AUCTION_BY_PROPERTY = 'auction/property';
  public static BB_UPDATE_AUCTION_AMOUNT = 'auction/placeBid';

  public static GET_ALL_EMAIL_TEMPLATE_CATEGORIES =
    'emailTemplates/getAllEmailTemplateCategories';
  public static GET_EMAIL_BY_CATEGORY = 'emailTemplates/getEmailByCategory';
  public static GET_EMAIL_TEMAPLATE_BY_ID = 'emailTemplates/getbyId';
  public static UPDATE_EMAIL_TEMPLATE = 'emailTemplates/update';

  public static GET_EMAIL_EMAIL_BRANDING = 'emailTemplates/emailbranding/get';
  public static UPDATE_EMAIL_EMAIL_BRANDING =
    'emailTemplates/emailbranding/update';

  public static GET_ALL_SMS_TEMPLATE_CATEGORIES =
    'smsTemplates/getAllSMSTemplateCategories';
  public static GET_SMS_BY_CATEGORY = 'smsTemplates/getSmsTemplateByCategory';
  public static GET_SMS_TEMAPLATE_BY_ID = 'smsTemplates/getbyId';
  public static UPDATE_SMS_TEMPLATE = 'smsTemplates/update';
  public static GET_SMS_TEMPLATES_CATEGORY =
    'smsTemplates/getAllSmsTemplateByCategory';

  public static GET_EMAIL_SMS_BRANDING = 'smsTemplates/emailbranding/get';
  public static UPDATE_SMS_BRANDING = 'smsTemplates/emailbranding/update';

  public static GET_EMAIL_BUFFER = 'adminUtil/emails';
  public static GET_SMS_BUFFER = 'adminUtil/sms';

  public static PROPERTY_AUCTION_HISTORY = 'property-auction-history-report';
  public static PROPERTY_AUCTION_REPORT_DOWNLOAD = 'auction/report/property';
}
