import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseURL = 'https://jsonplaceholder.typicode.com/posts';

  constructor(private http: HttpClient) {}

  getData() {
    return this.http.get(this.baseURL);
  }

  //

  setUserDetails(details: any) {
    localStorage.setItem('BB_USER_TOKEN', JSON.stringify(details));
  }

  getUserDetails() {
    var token = localStorage.getItem('BB_USER_TOKEN');
    if (token != null) {
      var userDetails = JSON.parse(token);
    }
    return userDetails;
  }

  removeUserDetails() {
    localStorage.removeItem('BB_USER_TOKEN');
  }
}
